import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { Button, Modal, Checkbox, Tooltip } from 'antd';
import { post } from '../../libs/utils/request';
import { useHistory, useLocation } from 'react-router';
import { LockOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { MailOutlined } from '@ant-design/icons';
import ReCAPTCHA from "react-google-recaptcha";

import { Formik } from 'formik';
import FormikInput from '../common/form/FormikInput';
import * as Yup from 'yup';
import './RegisterModal.css'
import LinkedInPage from '../Registration/Social/LinkedInPage';
import Google from '../Registration/Social/Google';
import ga4 from 'react-ga4';

const styles = {
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 22,
    color: '#282828',
    marginBottom: 16,
  },
  description: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 16,
    color: '#282828',
    marginBottom: 16,
  },
  footer: {
    width: '100%',
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column' as const,
    flexWrap: 'wrap' as const,
    gap: '10px 10px',
  },
  actionBtn: {
    fontWeight: 'bold' as const,
  },
};




const RegisterWallModal = ({
  visible, onCancel, title, description,
}: Props) => {
  const recaptchaRef: any = useRef();



  const [isChecked, setChecked] = useState(false)
  const [socialDatas, setSocialDatas] = useState(null);
  const [provider, setProvider] = useState("");
  const [profileImage, setProfileImage] = useState(undefined);
  const [birthDate, setbirthDate] = useState(undefined);
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [initialValues, setInitialValues] = useState(
    {
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      confirmpassword: '',
      token: ''
    }
  )

  useEffect(() => {
    if (visible === true) {
      ga4.ga('send', { 'hitType': 'pageview', 'page': 'signup', 'title': 'Sign Up' });
    }
  }, [isChecked])

  const onSubmit = async (values: any) => {
    let regData = {
      ...values,
      profileImage: profileImage,
      birthDate: birthDate,
      invitation: sessionStorage.getItem("invitation"),
    }

    regData.token = await recaptchaRef.current.executeAsync();

    try {
      const response: any = post('/register', regData)
        .then((result: any) => {
          setIsSubmitting(true)
          sessionStorage.setItem('user', JSON.stringify(result))
          localStorage.setItem('token', result.token)
          window.location.replace(window.location.protocol + '//' + window.location.host + '/registration')

        })
        .catch((err: any) => {
          console.log(err.response.data.message)
          setError(err.response.data.message)
          console.log(err)



        });

    } catch (err) {
      // console.log(err);
    }


  }
  const onSucces = (data: any, provider: string) => {
    setSocialDatas(data);
    setProvider(provider);
  }

  useEffect(() => {
    if (socialDatas && provider === "facebook") {
      setInitialValues({
        lastName: socialDatas.last_name,
        firstName: socialDatas.first_name,
        email: socialDatas.email,
        password: '',
        confirmpassword: '',
        token: ''
      })
      setProfileImage(socialDatas.picture.data.url)
      setbirthDate(socialDatas.birthday)
    } if (socialDatas && provider === "google") {
      setInitialValues({
        lastName: socialDatas.lastName,
        firstName: socialDatas.firstName,
        email: socialDatas.email,
        password: '',
        confirmpassword: '',
        token: ''
      })
      setProfileImage(socialDatas.avatar);
    }
    if (socialDatas && provider === "linkedin") {
      setInitialValues({
        lastName: socialDatas.user.lastName,
        firstName: socialDatas.user.firstName,
        email: socialDatas.user.email.emailAddress,
        password: '',
        confirmpassword: '',
        token: ''
      })
      setProfileImage(socialDatas.user.profileImage)
    }
  }, [socialDatas, provider])
  return (
    <div className="register-modal">
      <Modal
        className='registration-modal-view'
        visible={visible}
        onCancel={onCancel}
        destroyOnClose
        footer={null}
      >
        <div className="form-header">
          {title && (
            <div className="form-title">{title}</div>
          )}
        </div>

        <div style={styles.footer}>

          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              lastName: Yup.string()
                .required('Name is required'),
              firstName: Yup.string()
                .required('Name is required'),
              email: Yup.string()
                .required('Email is required')
                .email('Enter valid email'),
              password: Yup.string()
                .min(6, 'Must contain at least 6 characters')
                .required('This field is required'),
              confirmpassword: Yup.string()
                .required('This field is required')
                .oneOf([Yup.ref('password')], "Password not matched")
            })}
            onSubmit={(values, actions,) => onSubmit(values)}
            enableReinitialize
          >
            {(
              {
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                validateForm,
                handleReset
              }
            ) => (
              <form onReset={handleReset} onSubmit={handleSubmit} autoComplete="off">
                <div className="subtitle-container">
                  <div className="registration-subtitle">Sign up with email</div>
                  <Tooltip placement="top" title={'We use whatever data we can from your social account for making the registration process easier. Please, complete the missing fields.'}>
                    <QuestionCircleFilled className='registration-tooltip-icon' />
                  </Tooltip>
                </div>


                <div className="form-container">
                  <FormikInput
                    prefix={<MailOutlined />}
                    name='email'
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder='E-mail address'
                    error={errors.email && touched.email ? errors.email : ''}
                    withError
                    autofocus
                  />
                  <div className="input-row">
                    <FormikInput
                      label="First Name"
                      prefix={<UserOutlined />}
                      name='firstName'
                      value={values.firstName}
                      onChange={handleChange}
                      placeholder='Type your first name'
                      error={errors.firstName && touched.firstName ? errors.firstName : ''}
                      withError
                    />
                    <FormikInput
                      prefix={<UserOutlined />}
                      label="Last Name"
                      name='lastName'
                      value={values.lastName}
                      onChange={handleChange}
                      placeholder='Type your last name'
                      error={errors.lastName && touched.lastName ? errors.lastName : ''}
                      withError
                    />
                  </div>
                  <div className="input-row">
                    <FormikInput
                      prefix={<LockOutlined />}
                      label="Password"
                      name='password'
                      type='password'
                      value={values.password}
                      onChange={handleChange}
                      placeholder='Password'
                      error={errors.password && touched.password ? errors.password : ''}
                      withError
                    />
                    <FormikInput
                      prefix={<LockOutlined />}
                      name='confirmpassword'
                      label="Confirm password"
                      type='password'
                      value={values.confirmpassword}
                      onChange={handleChange}
                      error={errors.confirmpassword && touched.confirmpassword ? errors.confirmpassword : ''}
                      placeholder='Confirm password'
                      withError
                    />
                  </div>
                  <div className="check-row">
                    <Checkbox onChange={() => setChecked(!isChecked)} checked={isChecked} />
                    <div><p>By continuing, I agree to Pozi.io <a href={`/terms-and-conditions`} target="_blank" className="colored-text">Terms of Service</a> and knowledge our
                      <a href={`/privacy-policy`} target="_blank" className="colored-text"> Privacy Policy</a>, including the site cookie policy. </p></div>
                  </div>
                  <div className="errors-frombe-onepager">{error}</div>
                  <Button
                    className='register-modal-button'
                    style={{ ...styles.actionBtn, minWidth: 150, maxWidth: 50 }}
                    disabled={!isChecked}
                    type='primary'
                    size='large'
                    onClick={() => {
                      handleSubmit()
                    }}
                    loading={isSubmitting}
                  >
                    Register
                  </Button>

                  <div className="login-container">
                    <div className="login-description">Already have an account?</div>
                    <div className="login-text">
                      <a href="/login">Log in</a>
                    </div>
                  </div>
                </div>

                <div className="recaptcha">
                  <p>
                    This site is protected by reCAPTCHA and the
                    <a href="https://policies.google.com/privacy" target="_blank" className="colored-text"> Google Privacy Policy {" "} </a>  and
                    <a href="https://policies.google.com/terms" target="_blank" className="colored-text"> {" "} Terms of Service</a> {" "} apply.
                  </p>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  badge={'bottomright'}
                  size="invisible"
                  onChange={(e) => setFieldValue('token', e)}
                  sitekey="6LdYv0EdAAAAAFD7n7Z11i1-E5lM5gugcN3_szg3"
                />
              </form>
            )}
          </Formik>
        </div>

      </Modal>
    </div>
  );
};

interface Props {
  visible: boolean;
  onCancel: () => void;
  title?: string;
  description?: string | ReactElement;
}

RegisterWallModal.defaultProps = {
  title: '',
  description: '',
};

export default RegisterWallModal;

