import { Button, Checkbox, Select, Modal, message, Input, Form } from "antd";
import { Field, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
// import Select from 'react-select'
import { deleteReq, get, patch, post } from "../../libs/utils/request";
import { DateTimePicker } from "../common/form/DateTimePicker";
import FormikInput from "../common/form/FormikInput";
import FormikTextArea from "../common/form/FormikTextArea";
import { LocationSelector2 } from "../common/form/LocationSelector";
import ImageUploader from "../common/ImageUploader";
import { Interest, RegistrationData } from "../Registration/Registration";
import Roles from "../Registration/Registration_steps/Roles";
import SocialLinks from "../Registration/Registration_steps/SocialLinks";
import Chooser from "./Chooser";
import styles from "./NewProfileEditor.module.css";
import * as Yup from "yup";
import { Joi } from "express-validation";
import User from "../../user/User";
import eventbus from "../../libs/eventbus";
import AuthContex from '../../../store/AuthContext';

interface Props {
  userToEdit: any;
  onClose: () => void;
  callback: () => void;
}

const NewProfileEditor = ({ userToEdit, onClose, callback }: Props) => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthday: undefined,
    location: {
      placeId: "",
      description: "",
    },
    education: "",
    oneLiner: "",
    bio: "",
    currentPassword: "",
    newPassword: "",
    newPasswordAgain: "",
    socialLinks: [],
  });
  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [showAge, setShowAge] = useState(true);
  const [selectedRole, setSelectedRole] = useState({
    id: undefined,
    icon: undefined,
    name: "",
  });
  const [socialCount, setSocialCount] = useState(1);
  const [regData, setRegData] = useState({
    skillGroups: [],
    interestGroups: [],
    roles: [],
  });
  const [selectedInterests, setSelectedInterests] = useState([]);

  const [selectedSkills, setSelectedSkills] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [error, setError] = useState('');
  const [errorType, setErrorType] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [hasError, setHasError] = useState(true);
  const [emailModal, setEmailModal] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState("")
  const [email, setEmail] = useState("")
  const authCtx = useContext(AuthContex)

  //setter or formater functions
  const formatTime = (value: string) => {
    if (value) {
      return moment(value);
    }
    return null;
  };

  const onProfileImageChange = (url: string) => {
    setProfileImage(url);
  };

  const onCoverImageChange = (url: string) => {
    setCoverImage(url);
  };

  const checkLink = (e: any) => {
    if (
      e.target.value.length > 7 &&
      !/^https?:\/\//.test(e.currentTarget.value)
    ) {
      e.currentTarget.value = "http://" + e.currentTarget.value;
    }
  };

  const locationDisplay = (object: any) => {
    if (object.location && object.location.description) {
      return object.location.description;
    }

    if (object && object.location && object.location.city) {
      return `${object.location.country}, ${object.location.city}`;
    }
    if (object && object.location && !object.location.city) {
      return object.location.country;
    }
    return "";
  };

  //set the initial values
  useEffect(() => {
    getRegDatas();
    if (userToEdit) {
      let userLocation = "";
      if (userToEdit.location !== null) {
        userLocation += userToEdit.location?.country;
      }
      if (userToEdit.location !== null) {
        userLocation += ", " + userToEdit.location?.city;
      }
      let userSocial = [];
      if (userToEdit.socialLinks?.length !== 0) {
        for (let i = 0; i < userToEdit.socialLinks?.length; i++) {
          userSocial.push({
            type: userToEdit.socialLinks[i].type.name,
            url: userToEdit.socialLinks[i].url,
          });
        }
      }
      let userInterests = [];
      if (userToEdit.interests?.length !== 0) {
        for (let i = 0; i < userToEdit.interests?.length; i++) {
          userInterests.push({
            name: userToEdit.interests[i].name,
            id: userToEdit.interests[i].id,
          });
        }
      }
      let userSkills = [];
      if (userToEdit.skills?.length !== 0) {
        for (let i = 0; i < userToEdit.skills?.length; i++) {
          userSkills.push({
            name: userToEdit.skills[i].name,
            id: userToEdit.skills[i].id,
          });
        }
      }
      setFormValues({
        ...formValues,
        firstName: userToEdit.firstName,
        lastName: userToEdit.lastName,
        email: userToEdit.email,
        birthday: userToEdit.birthday
          ? formatTime(userToEdit.birthday)
          : undefined,
        location: {
          placeId: userToEdit.location ? userToEdit.location.placeId : "",
          description: userToEdit?.location?.description
            ? userToEdit?.location?.description
            : userLocation,
        },
        education: userToEdit.education ? userToEdit.education : "",
        oneLiner: userToEdit.oneLiner ? userToEdit.oneLiner : "",
        bio: userToEdit.bio !== null ? userToEdit.bio : "",
        socialLinks: userSocial,
      });
      setShowAge(userToEdit.showAge);
      setProfileImage(userToEdit.profileImage);
      setCoverImage(userToEdit.coverImage);
      setSocialCount(userToEdit.socialLinks.length > 0 ? userToEdit.socialLinks.length : 1);
      setSelectedRole(userToEdit.role);
      setSelectedInterests(userToEdit.interests);
      setSelectedSkills(userToEdit.skills);
    }
  }, [userToEdit]);

  const getRegDatas = async () => {
    try {
      const response = await get<RegistrationData>("/register/init");
      setRegData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const onRoleClick = (role: any) => {
    setSelectedRole(role);
  };
  const disabledDate = (current: any) =>
    current.year() < 1920 || current.year() > 2017;

  //
  const setingConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value)
  }

  const onSubmit = async (values: any, errors: any) => {
    validator();
    let userData = {
      coverImage: coverImage === undefined ? '' : coverImage,
      profileImage: profileImage === undefined ? '' : profileImage,
      role: selectedRole,
      interests: selectedInterests,
      skills: selectedSkills,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      birthday: values.birthday ? values.birthday._i : undefined,
      showAge: showAge,
      education: values.education,
      oneLiner: values.oneLiner,
      bio: values.bio,
      location: values.location,
      socialLinks: values.socialLinks,
    };
    if (hasError === false) {
      try {
        await patch<User>('/user', userData);
        if (values.currentPassword && values.newPassword && values.newPasswordAgain) {
          if (values.newPassword === values.newPasswordAgain) {
            await post('/user/changePassword', { password: values.currentPassword, newPassword: values.newPassword });
            authCtx.logout()
            message.success('Password changed. Please log in with the new password!');
          }
        }
        handleCancel();
        if (callback) {
          callback();
        }
      } catch (err) {
        if (err.response.status === 406) {
          // incorrect current password when changing password
          message.error('Incorrect password');
        } if (err.response.status === 403) {

          setErrorType('social');
          setError('Your social link is incorrect!');
          setHasError(true);
          message.error('Fix all errors');

        }if (err.response.status === 400){
          setErrorType('social');
          setError('You have to add social url!');
          setHasError(true);
          message.error('Fix all errors');
        }
        setHasError(false);
      }
    }
  }
  const handleCancel = () => {
    onClose();
  };

  const handleProfileDelete = async () => {
    try {
      const response = await deleteReq('/user', { password: confirmPassword });
      if (response === 'OK') {
        authCtx.logout();
        message.success('Profile deleted');
      }
    } catch (err) {
      if (err.response.status === 406) {
        message.error('Incorrect password');
      } else {
        //console.log(err);
      }
    }
  }
  const deleteItem = (index: number, values: any) => {
    formValues.socialLinks.splice(index, 1);
    setSocialCount(socialCount - 1);
    if (formValues.socialLinks[index] === undefined) {
      values.socialLinks.splice(index, 1);
    }
  };
  const validator = () => {
    if (selectedInterests.length < 3) {
      setErrorType('interest')
      setError('You have to select atleast 3 interest!')
      setHasError(true);
      message.error('Fix all errors');
    }
    if (selectedSkills.length < 1 || selectedSkills.length > 5) {
      setErrorType('skill')
      setError('You have to select atleast 1 interest, maximum 5 skill!')
      setHasError(true);
      message.error('Fix all errors');
    }
    if (selectedRole === undefined) {
      setErrorType('role')
      setError('You have to select your role!')
      setHasError(true);
      message.error('Fix all errors');
    }
  }
  useEffect(() => {
    if ((selectedSkills.length >= 1 && selectedSkills.length <= 5) && selectedInterests.length >= 3) {
      setErrorType('')
      setError('')
      setHasError(false)
    } else (setHasError(true))
  }, [selectedInterests, selectedSkills])


  const changeEmail=async ()=>{
    if(isValidEmail){
      await post('/user/change-email', { password: confirmPassword, newEmail: confirmEmail  })
      .then(()=>{
        message.info('Please check your emails to confirm your new email address', 5)
        setEmailModal(false)
        setError("")

      })
      .catch((err)=>{
        if(err.response.data.details)
          setError(err.response.data.details[0].newEmail?err.response.data.details[0].newEmail:err.response.data.details[0].password)
        else if(err.response.status===406)
          setError("Wrong password")
        else
          setError(err.response.data.message)
      })
    }
  }

  const settingConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value)
  }

  const settingNewEmail = (e: any) => {
    setEmail(e.target.value)
  }

  const settingConfirmEmail = (e: any) => {
    setConfirmEmail(e.target.value)
  }

  let isValidEmail=true
  if(email === confirmEmail)
    isValidEmail=true
  else
    isValidEmail=false
  return (
    <div className={styles.profile_editor}>
      <Formik
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .min(3, "The first name must contain minimum 3 characters")
            .max(50, "The first name is longer than 50 characters")
            .required("The first name is required"),
          lastName: Yup.string()
            .min(3, "The last name must contain minimum 3 characters")
            .max(50, "The last name is longer than 50 characters")
            .required("The last name is required"),
          email: Yup.string()
            .test("test-email", "Email is invalid", function (value) {
              const test = Joi.string()
                .email({ tlds: { allow: false } })
                .required()
                .validate(value);
              return test.error ? false : true;
            })
            .required("Email is required"),
          education: Yup.string()
            .max(140, "The education field is longer than 140 characters"),
          oneLiner: Yup.string()
            .min(3, "The one liner must contain minimum 3 characters")
            .max(140, "The one liner is longer than 140 characters")
            .required("The one liner is required"),
          bio: Yup.string()
            .min(3, "The bio must contain minimum 3 characters")
            .max(950, "The bio is longer than 950 characters")
            .required("The bio is required"),
          currentPassword: Yup.string()
            .min(6, "The password must contain minimum 6 characters"),
          newPassword: Yup.string()
            .min(6, "The new password must contain minimum 6 characters"),
          newPasswordAgain: Yup.string()
            .min(6, "The new password must contain minimum 6 characters")
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        })}
        enableReinitialize
        initialValues={formValues}
        onSubmit={(values, e) => {
          onSubmit(values, e);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          validateField,
        }) => (
          <form autoComplete="off">
            <div className={styles.top_part}>
              <div className={styles.image_uploaders}>
                <ImageUploader
                  className={styles.logo_uploader}
                  uploadText="Upload profile picture recommended 230x230"
                  images={profileImage}
                  type="single"
                  displayAllowed={false}
                  onChange={(url: string) => {
                    onProfileImageChange(url);
                  }}
                  showCropper = {true}
                  cropperData = {{width: 230 , height: 230 }}
                />
                <ImageUploader
                  className={styles.cover_uploader}
                  uploadText="Upload cover picture recommended 1300x300"
                  images={coverImage}
                  type="single"
                  displayAllowed={false}
                  onChange={(url: string) => {
                    onCoverImageChange(url);
                  }}
                  showCropper = {true}
                  cropperData = {{width: 1300 , height: 300 }}
                  cropType = 'cover'
                />
              </div>
              <div className={styles.roles}>
                {regData.roles.map((role: any) => (
                  <div
                    className={
                      role.id === selectedRole.id
                        ? styles.selected_role_item
                        : styles.role_item
                    }
                    key={role.id}
                    onClick={() => {
                      onRoleClick(role);
                    }}
                  >
                    <img
                      className={styles.role_icon}
                      src={role.icon}
                      alt="role icon"
                    />
                    {role.name}
                  </div>
                ))}
              </div>
              <div className={errorType === 'role' ? styles.error : styles.hide_error}>{error}</div>
            </div>
            <div className={styles.chooser_container}>
              <h1 className={styles.subtitle}>Your Interest</h1>
              <div className={styles.chooser_holder}>
                {selectedInterests.map((elem: any, index) => (
                  <div className={styles.chooser_item} key={index}>
                    {elem.name}
                  </div>
                ))}
              </div>
              <div className={errorType === 'interest' ? styles.error : styles.hide_error}>{error}</div>
              <Button
                type="primary"
                className={styles.primary_button}
                onClick={() => {
                  setShowModal(true);
                  setModalType("interest");
                }}
              >
                Choose your Interests
              </Button>
            </div>

            <div className={styles.chooser_container}>
              <h1 className={styles.subtitle}>Your Skills</h1>
              <div className={styles.chooser_holder}>
                {selectedSkills.map((elem: any, index) => (
                  <div className={styles.chooser_item} key={index}>
                    {elem.name}
                  </div>
                ))}
              </div>
              <div className={errorType === 'skill' ? styles.error : styles.hide_error}>{error}</div>
              <Button
                type="primary"
                className={styles.primary_button}
                onClick={() => {
                  setShowModal(true);
                  setModalType("skill");
                }}
              >
                Choose your Skills
              </Button>
            </div>
            <div className={styles.input_holder}>
              <FormikInput
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                prefix={
                  <img
                    className="step-userinfo-input-icon"
                    src="/images/user_info_icons/real_name.svg"
                    alt=""
                  />
                }
                placeholder="First name"
                error={errors.firstName && touched.firstName ? errors.firstName : ''}
                withError
              />
            </div>
            <div className={styles.input_holder}>
              <FormikInput
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                prefix={
                  <img
                    className="step-userinfo-input-icon"
                    src="/images/user_info_icons/real_name.svg"
                    alt=""
                  />
                }
                placeholder="Last name"
                error={errors.lastName && touched.lastName ? errors.lastName : ''}
                withError
              />
            </div>
            <div className={styles.email_input_holder}>
              <FormikInput
                name="email"
                value={values.email}
                onChange={handleChange}
                disabled
                prefix={
                  <img
                    className="step-userinfo-input-icon"
                    src="/images/user_info_icons/email.svg"
                    alt=""
                  />
                }
                placeholder="Email"
                error={errors.email && touched.email ? errors.email : ''}
                withError
              />
              <div className={styles.email_change}>
              <Button
                type="primary"
                className={styles.primary_button}
                onClick={() => {
                  setEmailModal(true);
                }}
              >
                Change your email
              </Button>
              </div>
            </div>
            <div className={styles.input_holder} >
              <DateTimePicker
                className={styles.birthday}
                name="birthday"
                value={values.birthday}
                onChange={(val: any) => {
                  setFieldValue("birthday", val);
                }}
                required={true}
                withTime={false}
                disabledDate={disabledDate}
                defaultPickerValue={moment("19900101")}
              />
              <div
                className={styles.show_birthday}
                onClick={() => {
                  setShowAge(!showAge);
                }}
                style={{
                  backgroundImage: `url(${showAge
                    ? "/images/user_info_icons/unhide.png"
                    : "/images/user_info_icons/hide.png"
                    } )`,
                }}
              ></div>
            </div>
            <div className={styles.input_holder}>
              <h1 className={styles.subtitle}>One-liner</h1>
              <FormikInput
                name="oneLiner"
                value={values.oneLiner}
                onChange={handleChange}
                placeholder="About you in one line"
                prefix={
                  <img
                    className="register-input-icon"
                    src="/images/user_info_icons/actual_oneliner.svg"
                    alt=""
                  />
                }
                maxLength={140}
                error={errors.oneLiner && touched.oneLiner ? errors.oneLiner : ''}
                withError
              />
            </div>
            <h1 className={styles.subtitle}>Bio - Short intro</h1>
            <div className={styles.input_holder}>
              <FormikTextArea
                name="bio"
                value={values.bio}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Example: Hi, I’m Nelson, but my friends call me Big Head. I’m a tech evangelist, I can help you with your demo, ‘cause I already watched the Facebook movie, A.I., The Net, and half of Tron. I also worked on projects like PiperChat and Nip Alert. And I was also on the cover of Wired. And actually inside too."
                autoSize={{ minRows: 5, maxRows: 10 }}
                prefix={
                  <img
                    className="register-input-icon"
                    src="/images/user_info_icons/bio.svg"
                    alt=""
                  />
                }
                maxLength={950}
                error={errors.bio && touched.bio ? errors.bio : ''}

              />
              <ul className="ul-register-bio">
                <li>
                  Introduce yourself (name, nickname, hobbies, funfacts etc.)
                </li>
                <li>Tell us about your work experiences</li>
                <li>Max character number is 950</li>
              </ul>
            </div>
            <div className={styles.passwords}>
              <FormikInput
                type="password"
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Current password"
                error={errors.currentPassword && touched.currentPassword ? errors.currentPassword : ''}
                withError
              />
              <FormikInput
                type="password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="New password"
                error={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                withError
              />
              <FormikInput
                type="password"
                name="newPasswordAgain"
                value={values.newPasswordAgain}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="New password again"
                error={errors.newPasswordAgain && touched.newPasswordAgain ? errors.newPasswordAgain : ''}
                withError
              />
            </div>
            <div>
              <h1 className={styles.subtitle}>Your city</h1>
              <img
                className="powered-by-google-onepager-edit"
                src="/images/google/powered_by_google.png"
                alt="Powered by Google"
              />
              <div className={styles.input_holder}>
                <LocationSelector2
                  className={styles.location}
                  suffixIcon={
                    <img
                      className="register-input-icon"
                      src="/images/user_info_icons/location.svg"
                      alt=""
                    />
                  }
                  placeholder={"Type your location"}
                  value={locationDisplay(values)}
                  onChange={(e: any) => {
                    setFieldValue("location.placeId", JSON.parse(e).placeId);
                    setFieldValue(
                      "location.description",
                      JSON.parse(e).description
                    );
                  }}
                />
              </div>
            </div>
            <div className={styles.education_input}>
              <h1 className={styles.subtitle}>Your education</h1>
              <FormikInput
                name="education"
                value={values.education}
                onChange={handleChange}
                placeholder="Your current/recent educational institution"
                maxLength={140}
              />
            </div>
            <div className={styles.social_holder}>
              <h1 className={styles.subtitle}>Social media</h1>
              {_.times(socialCount, (i) => (
                <>
                  <SocialLinks
                    type="string"
                    name={`socialLinks[${i}].url`}
                    value={values.socialLinks[i]?.url}
                    onChange={(e: any) => {
                      checkLink(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    dropName={`socialLinks[${i}].type`}
                    dropvalue={values.socialLinks[i]?.type}
                    deleteItem={() => {
                      deleteItem(i, values);
                    }}
                  />
                </>
              ))}
              {socialCount < 5 && (
                <div
                  className="more-social-register"
                  onClick={() => setSocialCount(socialCount + 1)}
                >
                  <img src="/images/user_info_icons/more_social.svg" alt="" />
                </div>
              )}
            </div>
            <div className={errorType === 'social' ? styles.social_error : styles.hide_error}>{error}</div>
            <Button type='danger' onClick={() => setShowConfirmModal(true)}>Delete profile</Button>
            <div className={styles.button_holder}>
              <Button
                className={styles.cancel_button}
                loading={false} size="large" onClick={()=>{handleCancel(); callback()}}>
                Cancel
              </Button>
              <Button
                className={styles.primary_button}
                type="primary"
                size="large"
                onClick={(e: any) => {
                  handleSubmit(e);

                }}
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <Modal visible={showModal} footer={null} className={styles.modal} closable={false} destroyOnClose>
        <Chooser
          length={3}
          selectedElements={
            modalType === "interest" ? selectedInterests : selectedSkills
          }
          Save={
            modalType === "interest" ? setSelectedInterests : setSelectedSkills
          }
          Cancel={setShowModal}
          groupElement={
            modalType === "interest"
              ? regData.interestGroups
              : regData.skillGroups
          }
          type={modalType}
        />
      </Modal>
      <Modal
        title='Are you sure?'
        visible={showConfirmModal}
        onOk={handleProfileDelete}
        onCancel={() => setShowConfirmModal(false)}
        okText='Delete'
        okType='danger'
      >
        <p>If you'd like to remove your account please enter your password:</p>
        <Input.Password placeholder='password' onChange={setingConfirmPassword} />
      </Modal>
      <Modal
        visible={emailModal}
        onOk={()=>{
                    changeEmail();
        }}
        onCancel={() => {setEmailModal(false); setError("")}}
        title="Change your email"
        okText='Change'
        okType='danger'
      >
        <form autoComplete="off" className={styles.email_form} >
          <input className="ant-input" autoComplete="off" placeholder='New email' onChange={settingNewEmail} />
          <input className="ant-input" autoComplete="off" placeholder='Confirm email' onChange={settingConfirmEmail} />
          {isValidEmail===false && <p className="organization-interest-error">Your email addresses don’t match</p>}
          <p className={styles.modal_p} >If you'd like to change your email please enter your password:</p>
          <input type="password" className="ant-input" placeholder="Please enter your password" autoComplete="new-password" onChange={settingConfirmPassword} />
          {error!=="" && <span className="organization-interest-error" id="errors-change-email">{error}</span>}
        </form>
      </Modal>
    </div>
  );
};

export default NewProfileEditor;
