import { Carousel, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { get, post } from "../../libs/utils/request";
import { user } from "../../libs/utils/user";
import { EventCard, HomePageEventCard } from "../CardView/ProjectCard";
import RegisterWallModal from "../common/RegiserWallModal";
import Slider from "../Sales/Slider";
import TestimonialCard from "../Sales/TestimonialCard";
import "./HomePage.css";
import { FrontendDataResponse } from "./IndexPage2";

const dataForSlider = [
  {
    img: "/images/index_page/growth 2.png",
    titleup: "Elő-Matchmaking hét és eseménysorozat 2024. 02. 05. - 02. 11.",
    title: "Emelkedj ki a tömegből!",
    description: "Üdvözlünk az első online egyetemi startup platformon!",
    button: user() ? "Show the dashboard" : "Regisztrálj itt",
  },
  {
    img: "/images/index_page/growth 2.png",
    titleup: "Elő-Matchmaking hét és eseménysorozat 2024. 02. 05. - 02. 11.",
    title: "Emelkedj ki a tömegből!",
    description: "Üdvözlünk az első online egyetemi startup platformon!",
    button: user() ? "Show the dashboard" : "Regisztrálj itt",
  },
];

const carouselResponsiveSettings = [
  {
    breakpoint: 1850,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1625,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1422,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1219,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1166,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 1016,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];
let enter: any;
let dateExpired = false;

const HomePage = () => {
  const [frontendData, setFrontendData] = useState<FrontendDataResponse>({
    numbers: {
      ideas: 0,
      projects: 0,
      users: 0,
    },
    latestNews: [],
    mentors: [],
    events: [],
    topLists: {
      ideas: [],
      mentors: [],
      projects: [],
    },
  });
  const [email, setEmail] = useState("");
  const [events, setEvents] = useState([]);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // setTimeout(() => {
  //   if (!sessionStorage.getItem("subscriptionClosed")) {
  //     setShowSubscriptionModal(true);
  //   }
  // }, 1 * 6000);
  // // , 5*60000);

  useEffect(() => {
    (async () => {
      try {
        const data: FrontendDataResponse = await get("/frontpage/");

        const event = await get<{ data: any[]; pagination: any }>("events", {
          currentPage: 1,
          limit: 4,
        });
        setEvents(event.data.reverse());
        setFrontendData(data);
      } catch (error) {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.extendedUser
        ) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(error.response.data.extendedUser)
          );
          if (
            !error?.response?.data?.extendedUser?.active &&
            !error?.response?.data?.extendedUser?.trial
          ) {
            window.location.replace("/trial-ended");
          }
        }
        message.error("Failed to fetch data.");
      }
    })();
  }, []);

  const sendSubscription = async () => {
    await post("/subscription", { email })
      .then((response) => {
        message.success(response, 5);
        let text = ((
          document.getElementById(
            "homepage-email-subscribe-input"
          ) as HTMLInputElement
        ).value = "");
        // sessionStorage.setItem("subscriptionClosed", "true");
        // setShowSubscriptionModal(false);
      })
      .catch((e) => {
        message.success("Subscribe failed", 5);
        message.error(e.response?.data?.message || "Network error");
      });
  };
  const getEvents = () => {
    async () => {
      try {
        const event = await get<{ data: any[]; pagination: any }>("events", {
          currentPage: 1,
          limit: 4,
        });
        setEvents(event.data.reverse());
      } catch (error) {
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.extendedUser
        ) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(error.response.data.extendedUser)
          );
          if (
            !error?.response?.data?.extendedUser?.active &&
            !error?.response?.data?.extendedUser?.trial
          ) {
            window.location.replace("/trial-ended");
          }
        }
        message.error("Failed to fetch data.");
      }
    };
  };

  const scrollTo = () => {
    var scrollDiv = document.getElementById("homepage-embed-video").offsetTop;
    window.scrollTo({ top: scrollDiv - 50, behavior: "smooth" });
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const CardView = () => {
    return dataForSlider.map((data) => (
      <div>
        <div className="homepage-text-on-image-titleup">
          {data.titleup}
        </div>
        <div className="homepage-text-on-image-first">

          <img src={data.img} className="homepage-text-on-image-desktop" />
          <div>
            <div className="homepage-text-on-image-title">{data.title}</div>
            <div className="homepage-text-on-image-description">
              {data.description}
            </div>
            <button
              type="button"
              className="ant-btn ant-btn-primary home-page-first-box-button"
            >
              {user() ? (
                <div onClick={() => window.location.replace("/dashboard")}>
                  {data.button}
                </div>
              ) : (
                <div onClick={() => setShowRegisterModal(true)}>
                  {data.button}
                </div>
              )}
            </button>
          </div>
          </div>
        </div>
    ));
  };

  return (
    <div className="home-page-main-container">
      <Carousel
        className="home-page-image-slider"
        slidesToShow={1}
        infinite={true}
        // autoplay
        // autoplaySpeed={2500}
        responsive={carouselResponsiveSettings}
      >
        {CardView()}
      </Carousel>

      <div className="home-page-counters-title">
        <div className="home-page-counters">
          <div>
            <img
              src="/images/index_page/users.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.users}
            </span>
            Users registered
          </div>
          <div className="homepage-counter-center-box">
            <img
              src="/images/index_page/ideas.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.ideas}
            </span>
            Ideas created
          </div>
          <div>
            <img
              src="/images/index_page/startups.png"
              className="home-page-counter-icon"
            />
            <span className="home-page-counters-number">
              {frontendData.numbers.projects}
            </span>
            Startups created
          </div>
        </div>
      </div>
      <div className="home-page-counters-title-box">
        <div className="home-page-counters-title-box-title">
          A HSUP program célja
        </div>
        <div className="home-page-counters-title-box-description">
          <div className="home-page-counters-title-box-text">
          A HSUP célja a hazai egyetemisták megismertetése az innováció világával, a modern vállalkozói ismeretekkel és különösen a startupok működésével, mindezt egy új, közös oktatási platformon keresztül.
          </div>
        </div>
      </div>
      <div className="home-page-testimonials">
        <div className="home-page-testimonials-inner-box">
          <img
            src="/images/sales/upper.svg"
            className="testimonial-first-image"
          />
          <Masonry
            breakpointCols={{ default: 2, 850: 1 }}
            className="home-page-testimonials-masonry"
            columnClassName="testimonials-list-column"
          >
            <TestimonialCard
              proposer={{
                image: "/images/index_page/payment bw.png",
                name: "Kredit",
                position: "",
                description:
                  "“Tudjuk, hogy most az egyetem sikeres elvégzése az egyik legfontosabb célod. A HSUP ebben is segíthet, hiszen ez a program egyetemi tárgyként indul, azaz az oktatási intézményed innovatív szemléletének köszönhetően kreditet kaphatsz a HSUP sikeres elvégzéséért.”",
                navigationLink: "",
              }}
              navigationTitle={""}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/save money bw.png",
                name: "Ösztöndíj",
                position: "",
                description:
                  "“Ha sikeresen teljesítetted az első félévet, és a második félévben is folytatod a tárgyat – azaz csapatban dolgozol tovább egy üzleti ötleten – HSUP-ösztöndíjban részesülsz. Havi 50 ezer forint támogatással segítünk abban, hogy ötleteddel felkészülj a valós piaci környezetre, és csak az álmaidra fókuszálhass! A második (tavaszi) szemeszter során legjobban teljesítő 15 csapat tagjai – csapattagonként – 1 millió forintos külön ösztöndíjban részesülnek. A startuppá válni képes 4 legígéretesebb csapat pedig – amennyiben megalapítják a vállalkozásukat és sikeresen vonnak be tőkét a piacról a tavaszi félév zárását követően – további vissza nem térítendő támogatáshoz juthatnak, startuponként akár 20 millió forint értékben.”",
                navigationLink: "",
              }}
              navigationTitle={""}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/teaching bw.png",
                name: "Mentorálás",
                position: "",
                description:
                  "“Minden támogatást megkapsz – és hogy igazán értékessé és egyedivé tegyük a programot, az egyetem oktatóin kívül a startup világ vállalkozóit is segítségül hívtuk. Ijesztőnek hangzik? Csak semmi pánik! Startupperként van, hogy bejön a számításunk, és van, hogy nem úgy alakulnak a dolgok, ahogy szeretnénk. Szerencsére a HSUP-ban a fejlődés számít!”",
                navigationLink: "",
              }}
              navigationTitle={""}
            />
            <TestimonialCard
              proposer={{
                image: "/images/index_page/settings bw.png",
                name: "Partneri kihívások",
                position: "",
                description:
                  "“A partneri kihívás során a partnerek olyan kihívást vagy problémát fogalmaznak meg, amiket a mindennapi működésük során tapasztalnak. Az első félévben dönthettek, hogy saját ötletet bemutató onepagert adtok le, vagy egy partner által megfogalmazott vállalati kihívásra dolgoztok ki megoldást. A kihívást adó partner átnézi és értékeli a beérkezett ötleteket, majd kiválasztja, azt az ötletet, amely kidolgozását szeretné támogatni mentorációval és egyéb szakmai felajánlással a második félév során. A partner által kiválasztott ötleten dolgozó csapat is (1-3 fős) ösztöndíj- támogatásban fog részesülni a többi, saját projektötleten dolgozó csapathoz hasonlóan. A HSUP platformon a partneri kihívások folyamatosan nyílnak meg a félév során, így ott tudtok majd a részletekről tájékozódni, és jelentkezni a kihívásra.”",
                navigationLink: "",
              }}
              navigationTitle={""}
            />
          </Masonry>
          <img
            src="/images/sales/bottom.svg"
            className="testimonial-second-image"
          />
        </div>
      </div>
      <div className="home-page-helping-startups-container">
        <div className="home-page-helping-startups-title">
          Miért a HSUP?
        </div>
        <div className="home-page-helping-startups-desc">
        Biztosak vagyunk benne, hogy mindenkiben van kreativitás, és képes a saját érdeklődési területén innovációra.
Lehetsz mérnök, közgazdász, bölcsész, orvos vagy ügyvéd, nap mint nap megújítasz valamit, csak talán nem tudatosan. Meg kell tanulnod, hogyan tudod megvalósítani az ötleteidet!

        </div>
      </div>
      <div className="home-page-join-startup-section" id="homepage-embed-video">
        <div className="home-page-join-startup-section-one">
          <div className="homepage-embed-video" id="homepage-embed-video">
            <iframe
              src="https://www.youtube.com/embed/Ae_duthFBlw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="home-page-join-startup-section-two">
          <span className="home-page-join-startup-section-two-title">
            Tanulj velünk kicsit másképp!
            <br />
            <br />
          </span>
          <span className="home-page-join-startup-section-two-description">
            <b>Karakterek:</b> A vállalkozás a felnőttek társasjátéka. Az, hogy milyen bábuval játszol, hogy egy vállalkozáson belül milyen szerepet tölthetsz be, több különböző tényezőtől függ. A HSUP-ban is a pálca választja a varázslót! A program egy szereposztó teszt kitöltésével kezdődik, amelynek alapján javaslatot teszünk arra, hogy a startup világ 4 legfontosabb alapítói szerepe (CEO - ügyvezető, CTO – technológiai vezető, projektmenedzser és designer) közül melyikben végezd el a képzést.
            <br />
            <br />
          </span>
        </div>
      </div>
      <div className="homepage-text-on-image-hubs">
        <div className="homepage-text-on-image-second">
          <div className="homepage-text-on-image-title-for-hubs">
            Egyedi tartalmak
          </div>
          <div className="homepage-text-on-image-description-for-hubs">
            A HSUP bónusztananyagai valódi kincsesbányát jelentenek. A teljes tananyag mellett a szerepednek megfelelően különböző személyre szabott tartalmakkal bővítheted tudásodat. Ha már unod a könyveket és jegyzeteket, mi a legjobb videókkal, podcastokkal és esettanulmányokkal készülünk neked.
          </div>
        </div>
      </div>
      <div>
      <img
          src="/images/index_page/space discovery.png"
          className="homepage-text-on-image-desktop"
        />
        <img
          src="/images/index_page/space discovery.png"
          className="homepage-text-on-image-mobile"
        />
      </div>
      <div className="home-page-events-title">
      Erősebb és értékesebb közösség közös építése
      </div>
      <div className="home-page-best-things">
        <div className="home-page-best-things-sections">
          <p className="home-page-best-things-sections-image-holder">
            <img
              src="/images/index_page/projects.png"
              className="home-page-best-things-image"
            />
          </p>
          <p className="home-page-best-things-title">Top startups</p>
          <p className="home-page-best-things-description">
          A legjobb csapatok listája az év végén itt lesz elérhető.
          </p>
        </div>
        <div className="home-page-best-things-sections home-page-best-things-sections-middle">
          <p className="home-page-best-things-sections-image-holder">
            <img
              src="/images/index_page/ideaMentor.png"
              className="home-page-best-things-image"
            />
          </p>
          <p className="home-page-best-things-title">Best ideas</p>
          <p className="home-page-best-things-description">
          A legjobb ötletek listája az év végén itt lesz elérhető.
          </p>
        </div>
        <img
          src="/images/index_page/space discovery.png"
          className="home-page-rocket-image"
        />
      </div>
      <div className="home-page-events">
        <div className="home-page-events-title">
          Stay tuned for our next events
        </div>
        <div className="home-page-events-cards">
          {events.map((event) => (
            <HomePageEventCard event={event} onChange={() => getEvents()} />
          ))}
        </div>
      </div>
      <div className="home-page-helping-startups-container">
        <div className="home-page-helping-startups-title home-page-helping-startups-title-with-padding">
        Interaktív, játékos tananyagok segítségével tanulhatsz
        </div>
        <div className="home-page-helping-startups-desc">
        Ha korábban még nem volt alkalmad elmerülni a piac, a pénz és az innováció világában, akkor sem kell megijedned. A Hungarian Startup University Programban interaktív, játékos tananyagok, személyre szabott tartalmak segítségével a saját tempódban tanulhatsz, és mi is mindvégig melletted leszünk. A magyar startup világ folyamatosan alakul, fejlődik, és ebben rád is számítunk. Biztosak vagyunk benne, hogy együtt még messzebbre juthatunk.
        </div>
      </div>
      <div className="homepage-text-on-image home-page-try-for-free">
        <img
          src="/images/index_page/build  product.png"
          className="homepage-text-on-image-desktop-two"
        />
      </div>
      <RegisterWallModal
        onCancel={() => setShowRegisterModal(false)}
        visible={showRegisterModal}
        title="Sign up for Pozi"
      />
    </div>
  );
};

export default HomePage;
