import { currentLanguage } from "../libs/i18n"
import { Interest, Skill } from "../components/Registration/Registration"
import { ILocation } from '../components/Competitions/Competition/Competition';

export default class User {
  id?: string
  email?: string
  firstName:string;
  lastName?:string;
  password?: string
  createdAt?: Date
  token?: string
  language?: string
  appLanguage?: string
  dataLanguage?: string
  profileImage?: string
  coverImage?: string /* TODO image refactor */
  role: {
    id?: string
    name?: string
    icon?: string
  }
  birthday?: string
  age?: number
  city?: string
  country?: string
  education?: string
  oneLiner?: string
  bio?: string
  active?: boolean
  interests: Interest[]
  interestGroups: []
  skills: Skill[]
  userPermission?: string[]
  uniqueTag: string
  location: ILocation;
  showAge: boolean;
  trial?: boolean;
  progress?: number;
  isMentor?: boolean;
  socialLinks?: any[];
  missingFields?: any[];
  awards?: any[];

  constructor(jsonString?: string) {
    if (jsonString) {
      const userData = JSON.parse(jsonString)
      this.firstName=userData.firstName
      this.lastName=userData.lastName
      this.id = userData.id
      this.email = userData.email
      this.password = userData.password
      this.token = userData.token
      this.language = userData.language
      this.createdAt = userData.createdAt
      this.profileImage = userData.profileImage
      this.active = userData.active
      this.role = userData.role ? {
        id: userData.role.id,
        name: userData.role.name,
        icon: userData.role.icon,
      } : {
        name: "Undefined"
      }
      this.birthday = userData.birthday
      this.showAge = userData.showAge
      this.city = userData.city
      this.country = userData.country
      this.education = userData.education
      this.oneLiner = userData.oneLiner
      this.bio = userData.bio
      this.interests = userData.interests
      this.interestGroups = userData.interestGroups
      this.skills = userData.skills
      this.userPermission = userData.userPermission
      this.uniqueTag = userData.uniqueTag
    }
    this.appLanguage = currentLanguage()
  }

}